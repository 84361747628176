(function ($, undefined) {

  /*============================================
  Header Functionalities
  ==============================================*/
  $(function () {
    const $header = $('.header'),
      $sectionWithDarkHeader = $('.js-header-dark');

    const getDarkHeaderSection = () => {
      let section;

      $sectionWithDarkHeader.each(function () {
        const $this = $(this);

        if ($this.isInViewport()) {
          section = $this;
          return false;
        }
      });

      return section;
    }

    // Change header modification if section is dependency of current visible section on page
    $(window).on('scroll load', function () {
      const scrollTop = $(this).scrollTop();

      const $visibleWhiteSectionThatIsInViewport = getDarkHeaderSection();

      if ($visibleWhiteSectionThatIsInViewport) {
        const sectionWhiteOffset = $visibleWhiteSectionThatIsInViewport.offset().top,
          TOP_OFFSET = 50;

        if (scrollTop >= sectionWhiteOffset - TOP_OFFSET) {
          $header.addClass('header_dark');
        } else {
          $header.removeClass('header_dark');
        }
      } else {
        $header.removeClass('header_dark');
      }
    });
  });

  /*============================================
  Swiper Slider
  ==============================================*/
  $(function () {
    const $heroSlider = $('.hero-swiper');
    const changeVideosAndImages = (slider) => {
      const $header = $('.header'),
        $heroVideoLight = $('.hero__video_light'),
        $heroVideoDark = $('.hero__video_dark'),
        $heroImageLight = $('.hero__image_light'),
        $heroImageDark = $('.hero__image_dark'),
        $turbineImage = $('.hero-turbine__img'),
        $heroScrollIcon = $('.hero__scroll'),
        indexCurrentSlide = slider.activeIndex,
        slideType = slider.slides[indexCurrentSlide].getAttribute('data-slide-type'),
        heroLightElements = [$heroVideoLight, $heroImageLight],
        heroDarkElements = [$heroVideoDark, $heroImageDark];

      const showLightVideo = () => {
        heroLightElements.forEach(item => item.fadeIn(500));
        heroDarkElements.forEach(item => item.fadeOut(500));
        $heroScrollIcon.fadeIn(500);
        $turbineImage.fadeOut(500);
        $header.removeClass('header_dark');
      }

      const showDarkVideo = () => {
        heroLightElements.forEach(item => item.fadeOut(500));
        heroDarkElements.forEach(item => item.fadeIn(500));
        $heroScrollIcon.fadeIn(500);
        $turbineImage.fadeOut(500);
        $header.removeClass('header_dark');
      }

      const showOnlyImage = () => {
        heroLightElements.forEach(item => item.fadeOut(500));
        heroDarkElements.forEach(item => item.fadeOut(500));
        $heroScrollIcon.fadeOut(500);
        $turbineImage.fadeIn(500);
        $heroSlider.addClass('hero-swiper_white');
      }

      if (slideType === 'light-video') {
        showLightVideo();
      } else if(slideType === 'dark-video') {
        showDarkVideo();
      } else if (slideType === 'white') {
        showOnlyImage();
        $header.addClass('header_dark');
      }
    }

    const disablePageScroll = (slider) => {
      $(window).scroll(() => {
        setTimeout( () => {
          slider.params.mousewheel.releaseOnEdges = false;
        }, 500);
      });
    }

    const enablePageScroll = (slider) => {
      setTimeout(() => {
        slider.params.mousewheel.releaseOnEdges = true;
      }, 1000);
    }

    // Hero swiper
    let heroSlider;
    if ($heroSlider.length) {
      heroSlider = new Swiper($heroSlider[0], {
        direction: "vertical",
        slidesPerView: 1,
        speed: 1000,
        touchReleaseOnEdges: true,
        threshold: 0,
        keyboard: {
          enabled: true,
        },
        mousewheel: {
          forceToAxis: true,
        },
        on: {
          slideChange: changeVideosAndImages,
          reachBeginning: disablePageScroll,
          reachEnd: enablePageScroll
        },
      });
    }

    // Info swiper
    new Swiper(".info-slider", {
      slidesPerView: 1,
      speed: 1000,
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });

   // enable hero swiper only on top scroll position
   const $hero = $('.hero'),
     heroTop = $hero.offset().top;

   if (heroSlider) {
     $(window).on('scroll load', function () {
       const scrollTop = $(window).scrollTop();

       if ($hero.isInViewport() && scrollTop === heroTop) {
         heroSlider.mousewheel.enable();
         heroSlider.allowTouchMove = true;
       } else {
         heroSlider.mousewheel.disable();
         heroSlider.allowTouchMove = false;
       }
     });
   }
  });

  /*============================================
  Make body static when header menu is open (mob)
  ==============================================*/
  $(function () {
    const $navMenu = $('#navbarMenu'),
      $body = $('body');

    $navMenu.on('show.bs.collapse', () => $body.css({'overflow-y': 'hidden'}));
    $navMenu.on('hide.bs.collapse', () => $body.css({'overflow-y': 'visible'}));
  });

  /*============================================
  Function Helpers
  ==============================================*/

  $.fn.isInViewport = function () {
    const $this = $(this);
    const elementTop = $this.offset().top;
    const elementBottom = elementTop + $(this).outerHeight();
    const viewportTop = $(window).scrollTop();
    const viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
  };
})(jQuery);